import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { SvgButton } from "../../common/Button";
import i18n from "i18next";
import {
    FooterSection,
    Title,
    NavLink,
    Extra,
    LogoContainer,
    Para,
    Large,
    Chat,
    Empty,
    SmallEmpty,
    Language,
    Label,
    LanguageSwitch,
    LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
    href: string;
    src: string;
}

const Footer = ({ t }: { t: TFunction }) => {
    const handleChange = (language: string) => {
        i18n.changeLanguage(language);
    };

    const onClckAppStore = () => {
        window.open(
            "https://apps.apple.com/us/app/%EC%B0%A8%EB%8B%A4%EC%9D%B4%EB%A0%89%ED%8A%B8/id6466152861",
            "_blank"
        );
    };
    const onClckPlayStore = () => {
        window.open(
            "https://play.google.com/store/apps/details?id=com.chadaapp",
            "_blank"
        );
    };

    const SocialLink = ({ href, src }: SocialLinkProps) => {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="25px" height="25px" />
            </a>
        );
    };

    return (
        <>
            <FooterSection>
                <Container>
                    <Row justify="space-between">
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Title>{t("(주) 모빌리티엔")}</Title>
                            <Row justify={"space-between"}>
                                <Large to="/">대표이사</Large>
                                <Para>{t("안명선")}</Para>
                            </Row>
                            <Row justify={"space-between"}>
                                <Large to="/">주소</Large>
                                <Para>
                                    04782 서울특별시 성동구 연무장5가길 7
                                    (성수동2가), 성수역 현대테라스타워 W610호
                                </Para>
                            </Row>
                            <SmallEmpty />
                            <Row justify={"space-between"}>
                                <Large to="/">사업자 번호</Large>
                                <Para>{t("530-81-02605")}</Para>
                            </Row>
                            <Row justify={"space-between"}>
                                <Large to="/">통신판매업신고</Large>
                                <Para>2024-강원원주-00249 호</Para>
                            </Row>
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <Title style={{ textAlign: "end" }}>고객센터</Title>
                            <Title style={{ textAlign: "end" }}>
                                {t("1544-7653")}
                            </Title>
                            <Empty />
                        </Col>
                    </Row>
                    <Empty />
                    <Row>
                        <Col lg={20} md={20} sm={22} xs={22}>
                            <LogoContainer>
                                <SvgIcon
                                    src="logo-box.svg"
                                    aria-label="homepage"
                                    width="200px"
                                    height="80px"
                                />
                            </LogoContainer>
                            <Row>
                                <span
                                    onClick={onClckPlayStore}
                                    style={{
                                        marginRight: 10,
                                        cursor: "pointer",
                                    }}
                                >
                                    <SvgIcon
                                        src="googleplay.svg"
                                        width="120"
                                        height="80"
                                    />
                                </span>
                                <span
                                    onClick={onClckAppStore}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <SvgIcon
                                        src="appstore.svg"
                                        width="120"
                                        height="80"
                                    />
                                </span>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>
        </>
    );
};

export default withTranslation()(Footer);
