import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
    HeaderSection,
    LogoContainer,
    NotHidden,
    CustomNavLinkSmall,
    Span,
} from "./styles";

const Header = ({ t }: { t: TFunction }) => {
    const [visible, setVisibility] = useState(false);

    const toggleButton = () => {
        setVisibility(!visible);
    };

    const MenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: "smooth",
            });
            setVisibility(false);
        };
        return (
            <>
                <CustomNavLinkSmall
                    onClick={() => scrollTo("companyIntroduce")}
                >
                    <Span>{t("회사 소개")}</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall
                    onClick={() => scrollTo("serviceIntroduce")}
                >
                    <Span>{t("서비스 소개")}</Span>
                </CustomNavLinkSmall>
            </>
        );
    };

    return (
        <HeaderSection>
            <Container>
                <Row justify={"center"}>
                    <LogoContainer to="/" aria-label="homepage">
                        <SvgIcon
                            src="logo-box.svg"
                            width="450px"
                            height="150px"
                        />
                    </LogoContainer>
                    <NotHidden>{/* <MenuItem /> */}</NotHidden>
                    {/* <Burger onClick={toggleButton}>
                        <Outline />
                    </Burger> */}
                </Row>
                {/* <Drawer closable={false} open={visible} onClose={toggleButton}>
                    <Col style={{ marginBottom: "2.5rem" }}>
                        <Label onClick={toggleButton}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline />
                            </Col>
                        </Label>
                    </Col>
                    <MenuItem />
                </Drawer> */}
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
